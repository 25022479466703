<template>
  <div>
    <b-row>
      <b-col cols="12">
        <div
          class="d-flex justify-content-between align-items-center pt-50 mb-2"
        >
          <div>
            <h2 class="font-weight-bolder m-0">Clientes y sus obras</h2>
          </div>
          <div class="d-flex align-items-center">
            <div>
              <b-link :to="{ name: 'createClient' }">
                <b-button variant="primary" size="sm" class="mr-1 text-nowrap">
                  {{ "Añadir nuevo cliente" }}
                </b-button>
              </b-link>
            </div>
            <b-input-group class="input-group">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchTerm"
                type="text"
                class="d-inline-block"
                :placeholder="'Búsqueda'"
                @keydown.enter.native="handleSearch"
              />
              <b-button
                @click="handleSearch"
                variant="primary"
                size="sm"
                class="mr-1 ml-1"
              >
                {{ "Buscar" }}
              </b-button>
            </b-input-group>
          </div>
        </div>
        <b-card no-body>
          <b-table
            hover
            responsive
            :items="items"
            :fields="columns"
            class="table-white"
          >
            <template #cell(contact)="data">
              {{ data.item.contact_person }}
              <span
                v-if="
                  data.item.contact_person_phone != null ||
                  data.item.contact_person != null
                "
                >|</span
              >
              {{ data.item.contact_person_phone }}
            </template>
            <template #cell(actions)="data">
              <span>
                <b-link
                  :to="{ name: 'viewClient', params: { id: data.item.id } }"
                  class="d-inline-block px-50 text-dark"
                >
                  <feather-icon icon="EyeIcon" />
                </b-link>
                <b-link
                  :to="{ name: 'editClient', params: { id: data.item.id } }"
                  class="d-inline-block px-50 text-dark"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-link>
                <span
                  @click="deleteClient(data.item.id, data.item.name)"
                  class="d-inline-block px-50 text-danger"
                >
                  <feather-icon icon="TrashIcon" />
                </span>
              </span>
            </template>
          </b-table>
          <div
            class="d-flex align-items-center justify-content-between mb-1 px-2"
          >
            <div class="d-flex align-items-center mb-0">
              <span class="text-nowrap"> {{ "Por página" }}: </span>
              <b-form-select
                v-model="pageLength"
                :options="pages"
                class="ml-50 mr-1"
                @input="handlePageChange"
              />
              <span class="text-nowrap"> {{ "Total" }}: {{ totalItems }}</span>
            </div>
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormInput,
  BFormSelect,
  BCard,
  BTable,
  BButton,
  BCol,
  BRow,
  BLink,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import store from "@/store/index";

export default {
  components: {
    BBadge,
    BAvatar,
    BCard,
    BTable,
    BButton,
    BPagination,
    BCol,
    BRow,
    BLink,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupPrepend,
  },
  data() {
    return {
      log: [],
      currentPage: 1,
      pageLength: 10,
      dir: false,
      pages: ["10", "15", "25"],
      columns: [
        {
          label: "Nombre",
          key: "name",
        },
        {
          label: "Contacto y tlf",
          key: "contact",
        },
        {
          label: "Nº de obras",
          key: "total_projects",
        },
        {
          label: "Acciones",
          key: "actions",
          class: "column-actions",
        },
      ],
      searchTerm: "",
    };
  },
  computed: {
    ...mapGetters({
      items: "clients/getItems",
      totalItems: "clients/getTotalItems",
    }),
  },
  created() {
    this.chargeData();
  },
  methods: {
    ...mapActions({
      list: "clients/getListClients",
      delete: "clients/delete",
    }),
    chargeData() {
      this.list({
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
      });
    },
    handleSearch() {
      this.currentPage = 1;
      this.chargeData();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.chargeData();
    },
    handlePageChange() {
      this.currentPage = 1;
      this.chargeData();
    },
    onSortChange(params) {
      this.currentPage = 1;
      this.chargeData();
    },
    deleteClient(id, name) {
      this.$bvModal
        .msgBoxConfirm(`¿Estás seguro de eliminar ${name}?`, {
          bodyClass: "bodyDelete",
          footerClass: "footerDelete",
          okVariant: "danger",
          okTitle: "Eliminar",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-danger",
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.delete(id);
          }
        });
    },
  },
};
</script>
